import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import FamousHorses from "../../../components/layout-famous-horses"

import Seo from "../../../components/seo"

import RadHorse from "./hambletonian10.png"
import "../../../styles/famous-horses.scss"
import ReactFitText from "react-fittext"

function HorseTimes() {
  return (
    <Layout bodyClass="famous-horses">
      <Seo title="Famous Horses: Hambletonian 10" />
      <FamousHorses>
        <div className="div2">
          <ReactFitText compressor={0.8}>
            <h2>Hambletonian 10, Father of modern trot</h2>
          </ReactFitText>
          <img
            src={RadHorse}
            alt="Hambletonian 10 in all his glory. He is a shiny horse."
            className="horse-image-right"
          />
          <p>
            Hambletonian 10 is a really famous horse from a super long time ago.
            I'm not even joking; this horse is from the mid 1800s and he has a{" "}
            <a href="https://en.wikipedia.org/wiki/Hambletonian_10">
              Wikipedia page
            </a>
            . And a{" "}
            <a href="https://en.wikipedia.org/wiki/Hambletonian_10#/media/File:Hambletonian_10_grave.jpg">
              granite obelisk
            </a>
            . Hambletonian 10 has a Wikipedia page, and I do not. And you
            probably don't either. If you do though, please follow me on{" "}
            <a href="https://twitter.com/diana_perkins">Twitter</a> because
            honestly, I could use some celebrity-based social media cred. Oh
            right, the horse. So yeah, he was apparently a horse that was both
            beautiful (a show horse) and fast (a trotter)! Wow!
          </p>
        </div>
        <div className="div3">
          <ReactFitText compressor={0.9}>
            <h2>[Opinion] Hambletonian 10's Low Withers Gained Him Glory</h2>
          </ReactFitText>
          <p>
            I don't know what that means, but I saw on{" "}
            <a href="https://en.wikipedia.org/wiki/Hambletonian_10">
              Wikipedia
            </a>{" "}
            that he "had an unusual build, being low at the withers (15.1
            hands), but high at the croup (15.3 hands)." Apparently we measure
            horses in hands.
          </p>
        </div>
        <div className="div4">
          <ReactFitText compressor={0.6}>
            <h2>Remembering Hambletonian</h2>
          </ReactFitText>
          <p>
            Hambletonian is known as the father of modern trot, which I thought
            meant that he brought a particularly cool horsey style to it, but no
            he is literally the dad. His bloodline was so strong that nearly all
            American trotters can be traced back to him. WHAT!
          </p>
        </div>
      </FamousHorses>
      <div class="button-holder">
        <HorseButton />
      </div>
    </Layout>
  )
}

export default HorseTimes
