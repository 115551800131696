import * as React from "react"
import PropTypes from "prop-types"

import "../styles/base.scss"
import "../styles/famous-horses.scss"
import famousHorsesLogo from "../images/famous-horses.svg"

const date = new Date()
const month = date.toLocaleString("default", { month: "long" })
console.log(month)
const dateOfHorsePublish = `${month} ${date.getDate()}, ${date.getFullYear()}`

const FamousHorses = ({ children }) => {
  return (
    <div className="famous-horses-wrapper">
      <div className="div1 famous-horses-header">
        <h1>
          <img src={famousHorsesLogo} alt="Famous Horses" />
          <span>Who are they and why do they trot</span>
        </h1>
        <p>
          <span>
            <strong>PRICE: </strong>Two apples
          </span>
          <span>{dateOfHorsePublish}</span>
        </p>
      </div>

      {children}
    </div>
  )
}

FamousHorses.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FamousHorses
